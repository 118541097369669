import DisplayDuration from '@asaprint/asap/components/DisplayDuration.js';
import PageHeader from '@asaprint/asap/components/PageHeader.js';
import PagePaper from '@asaprint/asap/components/PagePaper.js';
import {
  GraphQLReactTable as TGraphQLReactTable,
  GraphQLReactTableProps,
} from '@asaprint/asap/components/ReactTable.js';
import CenteredCell from '@asaprint/asap/components/tables/CenteredCell.js';
import DateTimeCell from '@asaprint/asap/components/tables/DateTimeCell.js';
import DateTimeFilter from '@asaprint/asap/components/tables/DateTimeFilter.js';
import UserCell from '@asaprint/asap/components/tables/UserCell.js';
import UserFilter from '@asaprint/asap/components/tables/UserFilter.js';
import TimeEntryEditButton from '@asaprint/asap/components/TimeEntryEditButton.js';
import { useAuth } from '@asaprint/asap/contexts/AuthContext.js';
import authorized from '@asaprint/asap/decorators/authorized.js';
import { RouteHandle } from '@asaprint/asap/interfaces.js';
import { DASHBOARD_ROUTE, ORDERS_RECEIVED_SHOW_ROUTE } from '@asaprint/asap/routes.js';
import { OrdersReceived_Load } from '@asaprint/asap/routes/__authenticated/orders/received/index.graphql';
import { TimeEntries_Load } from '@asaprint/asap/routes/__authenticated/time-entries/index.graphql';
import { TimeEntries_LoadQuery, TimeEntries_TimeEntryFragment } from '@asaprint/asap/schema.client.types.js';
import { hasPermission, Permission } from '@asaprint/common/access.js';
import { ORDER_RECEIVED_PHASE_NAMES, phaseOptions, phaseSx } from '@asaprint/common/constants/OrderReceived.js';
import { TIME_ENTRY_POSITION_NAMES } from '@asaprint/common/constants/TimeEntry.js';
import SelectFilter, { Option } from '@engined/client/components/table/SelectFilter.js';
import { LoaderFunctionArgs, MetaFunctionArgs } from '@engined/core/interfaces.js';
import { url } from '@engined/core/services/routes.js';
import { faUserClock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Chip, Link } from '@mui/material';
import React, { useMemo } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Column, TableState } from 'react-table';

const GraphQLReactTable = TGraphQLReactTable as unknown as React.NamedExoticComponent<
  GraphQLReactTableProps<TimeEntries_TimeEntryFragment>
>;

const positionOptions: Option[] = Object.keys(TIME_ENTRY_POSITION_NAMES).map((k) => ({
  value: k.toString(),
  label: TIME_ENTRY_POSITION_NAMES[k],
}));

interface OwnProps {}

type Props = OwnProps;

const emptyArray = [];
const defaultSortBy: TableState['sortBy'] = [{ id: 'endAt', desc: false }];

const mapData = (data: TimeEntries_LoadQuery) => data?.timeEntries?.edges.map((e) => e.node) || emptyArray;
const mapCount = (data: TimeEntries_LoadQuery) => data?.timeEntries?.pageInfo.count || 0;

const refetchQueries = ['TimeEntries_Load'];

const Index: React.FunctionComponent<Props> = () => {
  const { requestUser } = useAuth();
  const canManageTimeEntries = hasPermission(requestUser.permissions as Permission[], Permission.TimeEntriesManage);
  const canManageTimeEntriesAll = hasPermission(
    requestUser.permissions as Permission[],
    Permission.TimeEntriesManageAll,
  );

  const columns: Column<TimeEntries_TimeEntryFragment>[] = useMemo(
    (): Column<TimeEntries_TimeEntryFragment>[] => [
      {
        Header: 'Akcie',
        accessor: 'id',
        disableFilters: true,
        disableSortBy: true,
        width: 20,
        // eslint-disable-next-line react/display-name
        Cell: ({ cell: { value }, row: { original } }) => (
          <CenteredCell>
            {(canManageTimeEntriesAll || (canManageTimeEntries && original.user.id === requestUser.id)) && (
              <TimeEntryEditButton timeEntryId={value} refetchQueries={refetchQueries} />
            )}
          </CenteredCell>
        ),
      },
      {
        Header: 'Začiatok',
        accessor: 'startAt',
        Filter: DateTimeFilter,
        Cell: DateTimeCell,
        maxWidth: 80,
        width: 80,
      },
      {
        Header: 'Koniec',
        accessor: 'endAt',
        Filter: DateTimeFilter,
        // eslint-disable-next-line react/display-name
        Cell: DateTimeCell,
        maxWidth: 80,
        width: 80,
      },
      {
        Header: 'Používateľ',
        accessor: 'user',
        width: 80,
        Cell: UserCell,
        Filter: UserFilter,
        sortByToGraphQLVariable: () => 'user.fullName',
      },
      {
        Header: 'Prac. pozícia',
        accessor: 'position',
        width: 30,
        // eslint-disable-next-line react/display-name
        Filter: (props) => <SelectFilter {...props} options={positionOptions} />,
        // eslint-disable-next-line react/display-name
        Cell: ({ cell: { value } }) => <CenteredCell>{TIME_ENTRY_POSITION_NAMES?.[value] ?? '-'}</CenteredCell>,
        filterToGraphQLVariable: (value) => ({ eq: parseInt(value) }),
      },
      {
        Header: 'Čas',
        accessor: 'duration',
        // eslint-disable-next-line react/display-name
        Cell: ({ cell: { value } }) => (
          <CenteredCell>
            <DisplayDuration duration={value} />
          </CenteredCell>
        ),
        filterToGraphQLVariable: (value) => ({
          eq: parseFloat(value.replace(/,/g, '.')) * 3600,
        }),
        // eslint-disable-next-line react/display-name
        Footer: ({ rows, queryData }) => {
          return (
            <CenteredCell sx={{ fontWeight: 'bold', whiteSpace: 'nowrap' }}>
              <DisplayDuration
                duration={(queryData as TimeEntries_LoadQuery)?.timeEntries?.aggregate.duration.sum || 0}
              />
            </CenteredCell>
          );
        },
        width: 50,
      },
      {
        Header: 'Poznámka',
        accessor: 'note',
        width: 150,
      },
      {
        Header: 'Odberateľ - firma',
        id: 'orderReceived.moneyAddressName',
        accessor: (row) => row.orderReceived?.moneyAddressName,
        width: 150,
      },
      {
        Header: 'Číslo objednávky',
        id: 'orderReceived.moneyNumber',
        accessor: (row) => row.orderReceived?.moneyNumber,
        // eslint-disable-next-line react/display-name
        Cell: ({ cell: { value }, row: { original } }) => (
          <CenteredCell>
            {value ? (
              <Link
                component={RouterLink}
                to={`${url(ORDERS_RECEIVED_SHOW_ROUTE, {
                  id: original.orderReceived.id,
                })}`}
              >
                {value}
              </Link>
            ) : (
              '-'
            )}
          </CenteredCell>
        ),
        width: 80,
      },
      {
        Header: 'Kód produktu',
        id: 'product.orderReceived.moneyNumber',
        accessor: (row) => row.orderReceived?.moneyNumber,
        // eslint-disable-next-line react/display-name
        Cell: ({ cell: { value }, row: { original } }) => (
          <CenteredCell>
            {original.product ? (
              <Link
                component={RouterLink}
                to={`${url(ORDERS_RECEIVED_SHOW_ROUTE, {
                  id: original.orderReceived.id.toLowerCase(),
                })}#product-${original.product.id}`}
              >
                {value}-{original.product.position}
              </Link>
            ) : (
              '-'
            )}
          </CenteredCell>
        ),
        width: 80,
        sortByToGraphQLVariable: () => ['product.orderReceived.moneyNumber', 'product.position'],
        filterToGraphQLVariable: (value, language, filter) => {
          const parts = value.split('-');
          if (parts.length > 1 && /\d+/.test(parts[1])) {
            filter.product ??= {};
            filter.product.position = { eq: parseInt(parts[1], 10) };
          }

          return {
            ilike: `%${parts[0]}%`,
          };
        },
      },
      {
        Header: 'Názov produktu',
        id: 'product.name',
        accessor: (row) => row.product?.name,
        width: 120,
      },
      {
        Header: 'Názov fázy',
        id: 'phase.name',
        accessor: (row) => row.phase?.name,
        width: 120,
      },
      {
        Header: 'Názov fázy objednávky',
        accessor: 'orderReceivedPhase',
        // eslint-disable-next-line react/display-name
        Cell: ({ cell: { value } }) =>
          value && <Chip sx={{ ...phaseSx[value] }} label={ORDER_RECEIVED_PHASE_NAMES[value]} />,
        // eslint-disable-next-line react/display-name
        Filter: (props) => <SelectFilter {...props} options={phaseOptions} />,
        width: 150,
      },
    ],
    [canManageTimeEntries, canManageTimeEntriesAll, requestUser.id],
  );

  return (
    <>
      <PageHeader
        title={
          <>
            <FontAwesomeIcon icon={faUserClock} /> Pracovný log
          </>
        }
      />

      <PagePaper>
        <GraphQLReactTable
          id="timeEntries-timeEntries"
          columns={columns}
          defaultSortBy={defaultSortBy}
          query={TimeEntries_Load}
          mapCount={mapCount}
          mapData={mapData}
          canPrint={false}
          canXLSXExport={false}
        />
      </PagePaper>
    </>
  );
};

export default authorized(Permission.TimeEntriesRead)(Index);

export const loader = async ({ params, request, context: { req, apollo } }: LoaderFunctionArgs) => {
  if (ENV.SERVER) {
    const result = await apollo.query({ query: OrdersReceived_Load });
    return result.data;
  }
  return null;
};

export const handle: RouteHandle = {
  breadcrumbs: [
    { text: 'Dashboard', to: DASHBOARD_ROUTE },
    {
      text: 'Pracovný log',
    },
  ],
  meta: ({ meta }: MetaFunctionArgs) => ({
    title: `Pracovný log | ${meta.title}`,
  }),
};
